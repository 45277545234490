import AppButton from '../AppButton';
import { imgMainBanner } from '../../assets/images';
import { Link } from 'react-router-dom';
import { message, Select } from 'antd';
import { useState } from 'react';
import { EmailProps, initialEmail } from '../../types/email.type';
import {
  FetchAllHomepageResponse,
  HomepageProps,
} from '../../types/homepage.type';
import React from 'react';
import { httpRequest } from '../../helpers/api';
import { getErrorMessage } from '../../helpers/errorHandler';
import { LetsTalkModal } from '../';
import BeCarefull from '../../BeCarefullBackup';

export default function MainBanner() {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<EmailProps>(initialEmail);
  const [servicesData, setServicesData] = useState<Array<HomepageProps>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  React.useEffect(() => {
    const getServicesData = async () => {
      try {
        const res = await httpRequest.get<FetchAllHomepageResponse>(
          `/homepages`
        );

        setServicesData(res.data.payload.results);
      } catch (error) {
        message.error(getErrorMessage(error));
      }
    };
    getServicesData();
  }, []);

  const onClickSend = async () => {
    const selectedService = servicesData.filter((item: any) => {
      if (selectedTags?.includes(item.homepageId)) {
        return item;
      } else return null;
    });
    const newEmailData = {
      ...emailData,
      services: selectedService.map((item) => item.title),
    };

    await httpRequest.post('/contact-us', newEmailData);
    setIsModalVisible(false);
    setEmailData(initialEmail);
    message.success('Email Sent');
  };

  return (
    <div
      className='main-banner-home'
      style={{ backgroundImage: `url(${imgMainBanner})` }}
    >
      <div className='content site-layout-content'>
        <BeCarefull />

        <h1>
          We are <span />
          Creative Technology
          <span /> Solution Provider
        </h1>
        <p>Specialize in Quantum, Blockchain, IoT, and Transportation</p>

        <AppButton
          className='btn-start-project'
          size='large'
          type='primary'
          htmlType='submit'
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Start a Project
        </AppButton>
      </div>
      <Link to='#services' className='start-scroll' rel='noreferrer' />
      <LetsTalkModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        initialEmail={initialEmail}
        setEmailData={setEmailData}
        onClickSend={onClickSend}
        servicesData={servicesData}
        emailData={emailData}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
    </div>
  );
}
