import { WarningFilled } from '@ant-design/icons';
import { Alert, Button, Modal } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import useWindowSize from './hooks/useWindowSize';

export default function BeCarefull() {
  const { isMobile } = useWindowSize();

  return (
    <React.Fragment>
      <Alert
        type='warning'
        showIcon
        icon={<WarningFilled />}
        message={
          isMobile ? (
            <React.Fragment>
              <Paragraph style={{ fontSize: 11 }}>
                Untuk klarifikasi lengkap mengenai aplikasi penipuan yang
                menggunakan nama “Qbit” serta pernyataan resmi dari kami,
                silakan baca pengumuman berikut.
              </Paragraph>

              <SeeMore />
            </React.Fragment>
          ) : (
            'Untuk klarifikasi lengkap mengenai aplikasi penipuan yang menggunakan nama “Qbit” serta pernyataan resmi dari kami, silakan baca pengumuman berikut.'
          )
        }
        style={{ position: 'absolute', top: isMobile ? 50 : 100, margin: 20 }}
        action={!isMobile && <SeeMore />}
      />
    </React.Fragment>
  );
}

function SeeMore() {
  const { isMobile } = useWindowSize();
  const onClick = () => {
    Modal.info({
      title: 'Pengumuman',
      content: (
        <div style={isMobile ? { height: '70vh', overflowY: 'scroll' } : {}}>
          <Paragraph>
            Sehubungan dengan adanya laporan mengenai aplikasi yang menggunakan
            nama <b>"QBIT"</b> dan diduga terlibat dalam aktivitas penipuan,
            dengan ini kami menyatakan bahwa <b>PT Vanz Inovatif Teknologi</b>{' '}
            tidak memiliki keterkaitan, afiliasi, atau hubungan apa pun dengan
            aplikasi atau pihak yang dimaksud.
          </Paragraph>
          <Paragraph>
            <b>PT Vanz Inovatif Teknologi</b> adalah perusahaan software house
            yang bergerak di bidang pengembangan perangkat lunak dan layanan
            teknologi informasi. Kami selalu menjunjung tinggi standar
            profesionalisme dan etika bisnis dalam setiap produk serta layanan
            yang kami tawarkan.
          </Paragraph>
          <Paragraph>
            Kami mengimbau kepada masyarakat untuk selalu berhati-hati terhadap
            aplikasi atau platform yang mengatasnamakan QBIT. Untuk memastikan
            keaslian informasi atau layanan yang berkaitan dengan PT Vanz
            Inovatif Teknologi, silakan menghubungi kami melalui kanal resmi:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                Website Resmi: <a href='https://qbit.co.id'>qbit.co.id</a>
              </li>
              <li>
                Email Resmi:{' '}
                <a
                  href='mailto:contactus@qbit.co.id'
                  target='_blank'
                  rel='noreferrer'
                >
                  contactus@qbit.co.id
                </a>
              </li>
              <li>
                Media Sosial Resmi:{' '}
                <a
                  href='https://instagram.com/qbit.co.id'
                  target='_blank'
                  rel='noreferrer'
                >
                  instagram.com/qbit.co.id
                </a>
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            Jika Anda menemukan aplikasi atau pihak yang mencurigakan
            mengatasnamakan QBIT, kami sarankan untuk segera melaporkannya ke
            pihak berwenang.
          </Paragraph>
          <Paragraph>
            Demikian klarifikasi ini kami sampaikan agar tidak terjadi
            kesalahpahaman di masyarakat.
          </Paragraph>
          <Paragraph style={{ fontWeight: 'bold' }}>
            PT Vanz Inovatif Teknologi
          </Paragraph>
        </div>
      ),
      width: 800,
      closable: true,
    });
  };

  return (
    <Button type='primary' size='small' onClick={onClick}>
      Lihat Selengkapnya
    </Button>
  );
}
